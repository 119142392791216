@import 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap';

:root {
    --es-deep-grey-warm-1: #403B46;
    --es-deep-grey-warm-3: #847984;
    --es-deep-grey-warm-4: #BCB2BA;
    --es-deep-grey-warm-5: #E8E4EB;
    --es-white-warm-1: #FBF9F6;
    --es-white-warm-2: #F4F1ED;
    --es-white-warm-3: #EAE7E3;
    --es-white: #fff;
    --es-black: #000;
    --es-status-green: #DEECDC;

    // fallbacks - replaced by phoenix config
    --main-color: #EFDECE;
    --main-text-color: #403B46;
    --background-color: #EFDECE;
    --background-text-color: #403B46;
    --button-color: #403B46;
    --button-text-color: #fff;

    // override select2 styles
    --select2-single-height: auto;
    --select2-multiple-height: auto;
    --select2-label-text-color: var(--es-deep-grey-warm-1);
    --select2-selection-border-color: var(--button-color);
    --select2-selection-text-color: var(--es-deep-grey-warm-1);
    --select2-selection-focus-border-color: var(--button-color);
    --select2-selection-line-height: 1;
    --select2-selection-padding: 14px 15px;
    --select2-selection-multiple-padding: 14px 15px;
    --select2-placeholder-color: var(--es-deep-grey-warm-3);
    --select2-placeholder-overflow: ellipsis;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: Inter, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    margin: 0;
    color: var(--background-text-color);
    background-color: var(--background-color);
}

@media (width <= 1070px) {
    body {
        padding-bottom: 90px;
    }
}

img {
    vertical-align: middle;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    color: var(--background-text-color);
}

dialog,
.box {
    color: var(--es-deep-grey-warm-1);

    * {
        color: var(--es-deep-grey-warm-1);
    }
}

a, button {
    cursor: pointer;
}

.icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &.icon-easysolar {
        width: 91px;
        height: 11px;
        background-image: url('/assets/icons/icon-easysolar.svg');
    }

    &.icon-chevron-right-full {
        background-image: url('/assets/icons/icon-chevron-right-full.svg');
    }

    &.icon-chevron-left-full {
        background-image: url('/assets/icons/icon-chevron-left-full.svg');
    }

    &.icon-chevron-right {
        background-image: url('/assets/icons/icon-chevron-right.svg');
    }

    &.icon-chevron-left {
        background-image: url('/assets/icons/icon-chevron-left.svg');
    }

    &.icon-loading {
        background-image: url('/assets/icons/icon-loading.svg');
    }

    &.icon-share {
        background-image: url('/assets/icons/icon-share.svg');
    }

    &.icon-photovoltaic {
        background-image: url('/assets/icons/icon-photovoltaic.svg');
    }

    &.icon-minus {
        background-image: url('/assets/icons/icon-minus.svg');
    }

    &.icon-plus {
        background-image: url('/assets/icons/icon-plus.svg');
    }

    &.icon-sun {
        background-image: url('/assets/icons/icon-sun.svg');
    }

    &.icon-energy {
        background-image: url('/assets/icons/icon-energy.svg');
    }

    &.icon-currency {
        background-image: url('/assets/icons/icon-currency.svg');
    }

    &.icon-address {
        background-image: url('/assets/icons/icon-address.svg');
    }

    &.icon-bill {
        background-image: url('/assets/icons/icon-bill.svg');
    }

    &.icon-error {
        background-image: url('/assets/icons/icon-error.svg');
    }

    &.icon-close {
        background-image: url('/assets/icons/icon-close.svg');
    }

    &.icon-info {
        background-image: url('/assets/icons/icon-info.svg');
    }

    &.icon-house {
        background-image: url('/assets/icons/icon-house.svg');
    }

    &.icon-battery {
        background-image: url('/assets/icons/icon-battery.svg');
    }

    &.icon-charging-car {
        background-image: url('/assets/icons/icon-charging-car.svg');
    }
}

.btn {
    border-radius: 30px;
    border: unset;
    display: flex;
    height: 30px;
    align-items: center;
    font-size: 0.875rem;
    background-color: transparent;
    color: var(--button-color);

    .icon {
        margin: 0 5px;
    }

    &.btn-primary {
        padding-left: 15px;
        padding-right: 15px;
        background: var(--button-color);
        color: var(--button-text-color);
    }

    &.btn-hollow {
        border: 1px solid var(--button-color);
        color: var(--button-color);
        line-height: 1.375rem;
        padding: 6px 15px 6px 20px;

        &:not(.normal-icon) .icon {
            width: 15px;
            height: 15px;
        }
    }

    &.btn-with-icon {
        &:not(.left) {
            padding-right: 0;
        }

        &.left {
            padding-left: 0;
        }
    }
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 1.063rem;

    .form-errors {
        color: red;
        font-size: 0.625rem;
        line-height: 0.75rem;
    }

    label {
        color: var(--background-text-color);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
        letter-spacing: -0.0075rem;
    }

    input:not([type="checkbox"])::placeholder {
        color: var(--es-deep-grey-warm-4);
    }

    input:not([type="checkbox"]),
    select {
        width: 100%;
        height: 48px;
        padding: 11px 15px;
        border-radius: 5px;
        border: 1px solid var(--button-color);
        background: var(--es-white);
        color: var(--es-deep-grey-warm-1);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem;
        letter-spacing: -0.14px;

        &::placeholder {
            color: var(--es-deep-grey-warm-3);
        }
    }

    &.have-errors {
        padding-bottom: 0;

        label {
            color: red;
        }

        input,
        select {
            border-color: red;
        }
    }

    &.checkbox-form-group {
        label {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        input {
            width: 20px;
            height: 20px;
            margin: 2px;
            border: 1px solid var(--button-color);
            border-radius: 5px;
            appearance:none;

            &:checked {
                background-color: var(--button-color);
                background-image: url('/assets/icons/icon-check.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    &:not(.checkbox-form-group) .input-group {
        position: relative;

        em {
            position: absolute;
            top: 50%;
            left: 15px;
            width: 26px;
            height: 26px;
            transform: translateY(-50%);
            color: #BCB2BA;
            font-style: normal;
            line-height: 26px;
            font-size: 0.75rem;

            + input {
                padding-left: 50px;
            }
        }
    }
}

.badge {
    border-radius: 2px;
    padding: 4px;
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.625rem;
    letter-spacing: -0.00625rem;

    &.badge-success {
        background-color: var(--es-status-green);
        color: #7C9E77;
    }
}

@media (width <= 1100px) {
    app-results-aside {
        app-tab-list {
            flex-direction: column;

            .tab {
                flex: 1 !important;
                width: 100% !important;

                &:first-child::after {
                    transform: translateY(120%) !important;
                }

                &:last-child::after {
                    transform: translateY(-120%) !important;
                }

                &.active::after {
                    transform: translate(0) !important;
                }
            }
        }
    }
}

// select 2 overwrite

.select2-selection__rendered > span {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: -0.14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;

    &.ng-star-inserted {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .badge {
            margin-left: 15px;
            font-size: 0.625rem;
            font-style: normal;
            font-weight: 600;
            line-height: 0.625rem;
            letter-spacing: -0.00625rem;
        }
    }
}

.select2-label {
    display: none;
}

.select2 {
    .no-mobile {
        @media (width <= 600px) {
            display: none;
        }
    }
}

.check-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem;
        letter-spacing: -0.00875rem;

        &::before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-right: 15px;
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-image: url('/assets/icons/icon-check2.svg');
            background-color: var(--es-deep-grey-warm-1);
        }
    }
}

.is-loading {
    > * {
        opacity: 0;
    }

    &.show-content > * {
        opacity: .5;
    }
}
